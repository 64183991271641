import React from "react";
import Heading from "../../components/Heading/Heading";
import PortfolioElement from "../../components/PortfolioElement/PortfolioElement";
import BlogImage from "../../assets/Portfolio/blog.png";

const Portfolio = () => {
    return (
        <div className="flex flex-col items-center px-10 my-32">
            <Heading name="Portfolio"/>
            <div className="grid gap-7 justify-center PortfolioGrid PortfolioGrid__OneChild">
                <PortfolioElement projectTitle="Blog osobisty" imgSrc={BlogImage} url="https://predojs.x10.mx"/>
            </div>
        </div>
    );
};

export default Portfolio;