import React from "react";
import { FaReact, FaGithub, FaInstagram, FaLinkedin } from "react-icons/fa";
import { FaXTwitter } from "react-icons/fa6";
import { SiTailwindcss } from "react-icons/si";

const Footer = () => (
    <div className="flex flex-col gap-10 items-center py-10 mt-10">
        <div className="flex gap-10 text-white text-2xl sm:text-3xl">
            <a href="https://github.com/predojs" target="_blank" rel="noreferrer"><FaGithub className="hover:cursor-pointer"/></a>
            <a href="https://www.instagram.com/predo3n/?next=%2F" target="_blank" rel="noreferrer"><FaInstagram className="hover:cursor-pointer"/></a>
            <a href="https://www.linkedin.com/in/dawid-predecki-83b511152/" target="_blank" rel="noreferrer"><FaLinkedin className="hover:cursor-pointer"/></a>
            <a href="https://twitter.com/dawidpredecki" target="_blank" rel="noreferrer"><FaXTwitter className="hover:cursor-pointer"/></a>
        </div>
        <h3 className="flex items-center sm:text-lg">Created with ☕ and ❤️ using <FaReact className="text-[#61DBFB] mx-2"/> and <SiTailwindcss className="text-[#38BDF8] mx-2"/></h3>
    </div>
);

export default Footer; 