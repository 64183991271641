import React from "react";
import Heading from "../../components/Heading/Heading";
import { motion } from "framer-motion";


const About = () => (
    <motion.div className="flex flex-col my-32 px-10 items-center"
    initial={{opacity: 0, y: 100}}
    animate={{opacity: 1, y: 0}}
    transition={{duration: .5}}
    >
        <Heading name="O mnie"/>
        <div className="flex flex-col gap-5 lg:text-lg md:w-[900px]">
            <p>Jestem Dawid, młody i ambitny programista (<i>wannabe</i>😜). Interesuję się tworzeniem stron i aplikacji internetowych oraz aplikacji mobilnych. Posiadam solidną podstawę teoretyczną i praktyczną w zakresie technologii front-endowych.</p>
            <p>W pracy zawodowej zajmuję się obsługą sklepów internetowych, ale w wolnym czasie rozwijam swoje umiejętności programistyczne. Tworzę własne projekty, w tym tematyczne strony internetowe i przydatne aplikacje internetowe.</p>
            <p>Jestem osobą kreatywną i otwartą na nowe wyzwania. Chętnie podejmę się współpracy nad ciekawymi projektami, które pozwoliłyby mi wykorzystać moje dotychczasowe umiejętności i doświadczenie. Cały czas się rozwijam i poszerzam swoją wiedzę z zakresu technologii.</p>
        </div>
    </motion.div>
);

export default About;