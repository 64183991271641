import React from "react";
import { FaWordpress } from "react-icons/fa";

const PortfolioElement = ({...props}) => (
    <div className="rounded-3xl border border-white border-opacity-30 flex flex-col overflow-hidden backdrop-filter backdrop-blur-sm duration-300 hover:border-opacity-80">
        <a href={props.url} target="_blank" rel="noreferrer"><img className="hover:cursor-pointer hover:scale-105 duration-300" src={props.imgSrc} alt={props.projectTitle} /></a>
        <div className="flex flex-col items-center gap-3 p-5">
            <a href={props.url} target="_blank" rel="noreferrer"><p className="text-2xl duration-300 hover:cursor-pointer hover:tracking-wide hover:scale-110">{props.projectTitle}</p></a>
            <hr className="w-24 m-auto border-0 h-px bg-gradient-to-r from-[#FC5C7D] to-[#6A82FB]"/>
            <FaWordpress className="text-[#00749C] text-4xl md:text-6xl"/>
        </div>
    </div>
);

export default PortfolioElement;