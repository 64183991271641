import React from "react";
import Heading from "../../components/Heading/Heading";
import { FaHtml5, FaCss3Alt, FaReact, FaNodeJs, FaWordpress, FaGitAlt } from "react-icons/fa";
import { IoLogoJavascript } from "react-icons/io5";
import { SiTailwindcss } from "react-icons/si";


const Technologies = () => (
    <div className="flex flex-col items-center my-32 text-center px-10">
        <Heading name="Technologie"/>
        <div className="flex flex-col gap-8 lg:text-lg md:w-[900px]">
            <p>Poniżej przedstawiam technologie, z których korzystam przy tworzeniu stron i aplikacji.</p>
            <div className="grid grid-cols-3 gap-7 justify-center text-4xl md:flex md:text-6xl md:gap-10">
                <div className="flex flex-col items-center gap-1">
                    <FaHtml5 className="text-[#e34c26]"/>
                    <label className="text-lg">HTML</label>
                </div>
                <div className="flex flex-col items-center gap-1">
                    <FaCss3Alt className="text-[#2965f1]"/>
                    <label className="text-lg">CSS</label>
                </div>
                <div className="flex flex-col items-center gap-1">
                    <IoLogoJavascript className="text-[#F0DB4F]"/>
                    <label className="text-lg">Javascript</label>
                </div>
                <div className="flex flex-col items-center gap-1">
                    <FaReact className="text-[#61DBFB]"/>
                    <label className="text-lg">React</label>
                </div>
                <div className="flex flex-col items-center gap-1">
                    <SiTailwindcss className="text-[#38BDF8]"/>
                    <label className="text-lg">Tailwind</label>
                </div>
                <div className="flex flex-col items-center gap-1">
                    <FaNodeJs className="text-[#3C873A]"/>
                    <label className="text-lg">Node.js</label>
                </div>
                <div className="flex flex-col items-center gap-1">
                    <FaWordpress className="text-[#00749C]"/>
                    <label className="text-lg">Wordpress</label>
                </div>
                <div className="flex flex-col items-center gap-1">
                    <FaGitAlt className="text-[#F1502F]"/>
                    <label className="text-lg">Git</label>
                </div>
            </div>
        </div>
    </div>
);

export default Technologies;