import React from "react";
import { motion } from "framer-motion";

const Header = () => (
    <motion.div className="flex items-center justify-between text-white px-8 py-5 md:px-12 md:py-5 sticky top-0 backdrop-filter backdrop-blur-sm z-[1]"
    initial={{y: -100}}
    animate={{y: 0}}
    transition={{
        duration: .5
    }}
    >
        <h1 className="text-2xl font-semibold font-poppins md:text-3xl"><a href="/">Predo.js</a></h1>
        <a className="text-lg font-poppins" href="https://predojs.x10.mx" target="_blank" rel="noreferrer">Blog</a>
    </motion.div>
);

export default Header;