import React from 'react';
import Header from '../../components/Header/Header';
import Footer from '../../components/Footer/Footer';
import About from '../About/About';
import Portfolio from '../Portfolio/Portfolio';
import Introduction from '../Introduction/Introduction';
import { Particles, initParticlesEngine } from '@tsparticles/react';
import { useEffect, useMemo, useState } from "react";
import { loadFirePreset } from '@tsparticles/preset-fire';
import Technologies from '../Technologies/Technologies';
import Background from "../../assets/background.png"
import { AnimatePresence } from 'framer-motion';

const App = () => {
    const [init, setInit] = useState(false);

    useEffect(() => {
        initParticlesEngine(async (engine) => {
        await loadFirePreset(engine);
        }).then(() => {
        setInit(true);
        });
    }, []);

  const particlesLoaded = (container) => {
    console.log(container);
  };

  const options = useMemo(
    () => ({
        preset: "fire",
        fullScreen: {
            enable: true,
            zIndex: -1,
        },
        background: {
            image: `url(${Background})`,
            size: "cover",
        },
        particles: {
            opacity: {
                value: 0.2,
            },
            move: {
                speed: 0.5
            },
            color: {
                value: "#fff",
            }
        },
    }),
    [],
  );

    if (init) {
        return <AnimatePresence className='flex flex-col'>
            <Particles
                id="tsparticles"
                particlesLoaded={particlesLoaded}
                options={options}
            />
            <Header />
            <Introduction />
            <About />
            <Technologies />
            <Portfolio />
            <Footer />
        </AnimatePresence>
      }
    
      return <></>;
};

export default App;
