import { React, useState } from "react";
import Avatar from "../../assets/avatar.jpg";
import { motion } from "framer-motion";

const Introduction = () => {
    const [buttonText, setButtonText] = useState("Skontaktuj się");

    const handleClick = () => {
        setButtonText('predecki.dawid@gmail.com');
    };


    return (
        <motion.div className="text-center flex flex-col items-center px-10 mb-32 mt-24"
        initial={{opacity: 0, scale: 1.5}}
        animate={{opacity: 1, scale: 1}}
        transition={{duration: .5}}
        >
            <img src={Avatar} alt="avatar" className="rounded-full border-4 border-white drop-shadow-xl w-64 h-64 mb-7 lg:w-78 lg:h-78" loading="lazy"/>
            
            <h1 className="text-5xl text-white font-poppins font-semibold mb-1 lg:text-6xl">Dawid Predecki</h1>
            
            <h3 className="text-2xl mb-5 text-transparent bg-clip-text bg-gradient-to-r from-[#FC5C7D] to-[#6A82FB] lg:text-3xl">hello_world;</h3>
            
            <p className="mb-5 max-w-lg lg:text-lg lg:max-w-xl">Młody pasjonat i twórca stron i aplikacji internetowych. Odezwij się i razem stwórzmy coś fajnego!</p>
            
            <button onClick={handleClick} className="border-2 border-white font-poppins text-white tracking-wider font-semibold px-6 py-4 text-lg rounded-full hover:text-black hover:bg-white transition-all duration-150">{buttonText}</button>
        </motion.div>
    )
};

export default Introduction;